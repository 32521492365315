<template>
	<div ref="formContainer">
		<div v-for="data in datas" :key="data.id">
			<b-card no-body class="card-statistics" v-if="data.type == 'QCM'">
				<b-card-body class="statistics-body">
					<b-row>
						<b-col md="6" sm="6" class="mb-2 mb-md-0  mb-xl-0">
							<b-media no-body>
								<b-media-aside class="mr-2">
									<b-avatar size="48" variant="light-danger">
										<feather-icon size="24" icon="BoxIcon" />
									</b-avatar>
								</b-media-aside>
								<b-media-body class="my-auto">
									<h4 class="font-weight-bolder mb-0">
										{{ data.title }}
									</h4>
								</b-media-body>
							</b-media>
						</b-col>

						<b-col md="4" sm="6" class="mb-2 mb-md-0  mb-xl-0">
							<span v-for="(choice, index) in data.choices" :key="choice.id">
								<b-button
									:id="`tooltip-target-${choice.id}`"
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									:variant="choice.right ? 'success' : 'warning'"
									class="btn-icon mr-2"
								>
									A{{ index + 1 }}
								</b-button>
								<b-tooltip
									:target="`tooltip-target-${choice.id}`"
									triggers="hover"
									no-fade
								>
									{{ choice.name }}
								</b-tooltip>
							</span>
						</b-col>
						<b-col md="2" sm="6">
							<b-media no-body>
								<b-media-aside class="mr-2">
									<b-avatar size="48" variant="light-danger">
										<feather-icon size="24" icon="BoxIcon" />
									</b-avatar>
								</b-media-aside>
								<b-media-body class="my-auto">
									<h4 class="font-weight-bolder mb-0">
										{{ getAverageQCM(data.users) }}
									</h4>
								</b-media-body>
							</b-media>
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<b-col v-for="user in data.users" :key="user.id" md="3" sm="6">
							<b-card>
								<b-card-body style="padding:0">
									<b-row>
										<b-col md="6" sm="6"
											>{{ user.firstname }} {{ user.lastname }}</b-col
										>
										<b-col md="6" sm="6" v-if="user.answer.exist === true">
											<b-button
												v-ripple.400="'rgba(255, 255, 255, 0.15)'"
												:variant="user.answer.isRight ? 'success' : 'warning'"
												class="btn-icon mr-2"
											>
												A{{ user.answer.index + 1 }}
											</b-button>
										</b-col>
										<b-col md="6" sm="6" v-else>
											<b-button
												v-ripple.400="'rgba(255, 255, 255, 0.15)'"
												variant="danger"
												class="btn-icon mr-2"
											>
												NA
											</b-button>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>

			<b-card no-body class="card-statistics" v-else-if="data.type == 'RATING'">
				<b-card-body class="statistics-body">
					<b-row>
						<b-col md="9" sm="6" class="mb-2 mb-md-0  mb-xl-0">
							<b-media no-body>
								<b-media-aside class="mr-2">
									<b-avatar size="48" variant="light-primary">
										<feather-icon size="24" icon="BoxIcon" />
									</b-avatar>
								</b-media-aside>
								<b-media-body class="my-auto">
									<h4 class="font-weight-bolder mb-0">
										{{ data.title }}
									</h4>
								</b-media-body>
							</b-media>
						</b-col>

						<b-col md="3" sm="6" class="mb-2 mb-md-0  mb-xl-0">
							<b-form-rating
								variant="warning"
								:value="getAverageRating(data.users)"
								readonly
								no-border
								show-value
							/>
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<b-col v-for="user in data.users" :key="user.id" md="3" sm="6">
							<b-card>
								<b-card-body style="padding:0">
									<b-row>
										<b-col md="6" sm="6"
											>{{ user.firstname }} {{ user.lastname }}</b-col
										>
										<b-col md="6" sm="6" v-if="user.answer.exist === true">
											<b-form-rating
												variant="warning"
												:value="user.answer.value"
												readonly
												no-border
												show-value
											/>
										</b-col>
										<b-col md="6" sm="6" v-else>
											<b-button
												v-ripple.400="'rgba(255, 255, 255, 0.15)'"
												variant="danger"
												class="btn-icon mr-2"
											>
												NA
											</b-button>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
			<b-card
				no-body
				class="card-statistics"
				v-else-if="data.type == 'TEXT_AREA'"
			>
				<b-card-body class="statistics-body">
					<b-row>
						<b-col md="9" sm="6" class="mb-2 mb-md-0  mb-xl-0">
							<b-media no-body>
								<b-media-aside class="mr-2">
									<b-avatar size="48" variant="warning">
										<feather-icon size="24" icon="TrendingUpIcon" />
									</b-avatar>
								</b-media-aside>
								<b-media-body class="my-auto">
									<h4 class="font-weight-bolder mb-0">
										{{ data.title }}
									</h4>
								</b-media-body>
							</b-media>
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<b-col v-for="user in data.users" :key="user.id" md="6" sm="6">
							<b-card>
								<b-card-body style="padding:0">
									<b-row>
										<b-col md="4"
											>{{ user.firstname }} {{ user.lastname }}</b-col
										>
										<b-col md="8" v-if="user.answer.exist === true">
											<b-form-textarea readonly :value="user.answer.value" />
										</b-col>
										<b-col md="8" v-else>
											<b-button
												v-ripple.400="'rgba(255, 255, 255, 0.15)'"
												variant="danger"
												class="btn-icon mr-2"
											>
												NA
											</b-button>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
		</div>
	</div>
</template>

<script>
import {
	BCard,
	BCardBody,
	BRow,
	BCol,
	BMedia,
	BMediaAside,
	BAvatar,
	BMediaBody,
	BFormRating,
	BFormTextarea,
	BButton,
	BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Admin } from "@/mixins/admin.js";
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BFormRating,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BFormTextarea,
		BButton,
		BTooltip,
	},
	mixins: [Admin],
	directives: {
		Ripple,
	},
	async mounted() {
		await this.loadAnswers();
	},
	methods: {
		async loadAnswers() {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.getAnswerByWebinarAndSurvey(
					this.$route.params.surveyId,
					this.$route.params.webinarId
				);
				if (response.status == 1) {
					this.datas = response.datas;
				} else {
					this.$sweetError("AF-87");
				}
				loader.hide();
			} catch (e) {
				loader.hide();
				this.$sweetError("AF-88");
			}
		},
		getAverageRating(users) {
			let count = 0;
			let sum = 0;

			for (const u of users) {
				if (u.answer.exist === true) {
					count = count + 1;
					sum = sum + u.answer.value;
				}
			}

			return sum / count;
		},
		getAverageQCM(users) {
			let count = 0;
			let sum = 0;

			for (const u of users) {
				if (u.answer.exist === true) {
					count = count + 1;
					if (u.answer.isRight === true) sum = sum + 1;
					
				}
			}

			return `${sum} / ${count}`;
		}
	},
	data() {
		return {
			datas: [],
		};
	},
};
</script>
